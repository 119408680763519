import { UIHeading5, UIText2 } from '@cian/ui-kit';
import { useSelector } from 'react-redux';

import * as styles from './styles.css';
import { IFskBanner } from '../../../types/fskContent/fskContent';
import { IApplicationState } from '../../../types/redux';
import { Ticker } from '../../Ticker';
import { DesktopBannerTemplate, IDesktopBannerTemplateProps } from '../DesktopBannerTemplate';

type TDesktopBannerProps = Omit<
  IDesktopBannerTemplateProps,
  'title' | 'description' | 'href' | 'hasBackground' | 'copyright' | 'logo'
>;

export function DesktopBanner(props: TDesktopBannerProps) {
  const fskBanner = useSelector<IApplicationState, IFskBanner>(state => state.fskBanner);

  return (
    <DesktopBannerTemplate
      href={fskBanner.hitUrl}
      title={<UIHeading5 color="text-inverted-default">{fskBanner.title}</UIHeading5>}
      background={fskBanner.background}
      description={<UIText2 color="text-inverted-default">{fskBanner.description}</UIText2>}
      copyright={<Ticker text={fskBanner.disclaimer} />}
      logo={fskBanner.logo}
      rootClassName={styles['desktop-banner']}
      {...props}
    />
  );
}
