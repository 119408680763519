import { IconChevronLeft16, IconChevronRight16, UIHeading5 } from '@cian/ui-kit';
import { splitEvery } from 'ramda';
import * as React from 'react';

import { calendarReducer } from './reducer';
import { ECalendarActionTypes, ICalendarContainerProps } from './types';
import { prepareInitialState } from './utils';
import { ArrowButton } from '../../components/ArrowButton';
import { Calendar } from '../../components/CalendarSection';
import { Cell } from '../../components/Cell';
import { Month } from '../../components/Month';
import { Week } from '../../components/Week';
import { DAYS_OF_WEEK, MONTHS, MONTHS_TO_DISPLAY } from '../../constants';
import { ICalendarDay, TCalendarDay } from '../../types';

export const CalendarContainer = ({ onChange, ...props }: ICalendarContainerProps) => {
  const [state, dispatch] = React.useReducer(calendarReducer, prepareInitialState(props));

  const visibleMonths = React.useMemo(() => {
    return state.calendar.slice(state.currentPage, state.currentPage + MONTHS_TO_DISPLAY);
  }, [state.calendar, state.currentPage]);

  const handlePrevClick = React.useCallback((e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();

    dispatch({ type: ECalendarActionTypes.PrevPage });
  }, []);

  const handleNextClick = React.useCallback((e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();

    dispatch({ type: ECalendarActionTypes.NextPage });
  }, []);

  const handleCellClick = React.useCallback(
    (day: ICalendarDay) => () =>
      dispatch({
        type: ECalendarActionTypes.ClickOnDate,
        day,
      }),
    [],
  );

  const prevButton = state.isPrevPageAvailable && (
    <ArrowButton onClick={handlePrevClick}>
      <IconChevronLeft16 color="icon-main-default" />
    </ArrowButton>
  );
  const nextButton = state.isNextPageAvailable && (
    <ArrowButton onClick={handleNextClick}>
      <IconChevronRight16 color="icon-main-default" />
    </ArrowButton>
  );

  const months = visibleMonths.map((month, monthIndex) => {
    const { number, year, days } = month;

    return (
      <Month
        key={monthIndex}
        header={
          <UIHeading5>
            {MONTHS[number]}&nbsp;{year}
          </UIHeading5>
        }
      >
        <Week>
          {DAYS_OF_WEEK.map((text, index) => (
            <Cell key={index} type="day_of_week" content={text} />
          ))}
        </Week>
        {splitEvery(7, days).map((week: TCalendarDay[], index) => (
          <Week key={`week-${index}`}>
            {week.map((day, dayIndex) => {
              if (day) {
                let onClick: VoidFunction | undefined = handleCellClick(day);

                if (day.type === 'disabled') {
                  onClick = undefined;
                }

                return <Cell key={dayIndex} type={day.type} content={day.number} onClick={onClick} />;
              }

              return <Cell key={dayIndex} type="none" />;
            })}
          </Week>
        ))}
      </Month>
    );
  });

  React.useLayoutEffect(() => {
    if (state.from && state.to && state.updated) {
      dispatch({ type: ECalendarActionTypes.ResetUpdated });

      onChange(state.from, state.to);
    }
  }, [onChange, dispatch, state.from, state.to, state.updated]);

  return <Calendar months={months} prevButton={prevButton} nextButton={nextButton} />;
};
