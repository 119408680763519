import { TColors } from '@cian/ui-kit-design-tokens/colors';
import { IconToggleHeartOn12, IconFinancePercent12 } from '@cian/ui-kit-design-tokens/icons';
import { createElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

interface IGetIcon {
  isFavorite?: boolean;
  isPromo?: boolean;
  isActive?: boolean;
  isViewed?: boolean;
}

export const getIcon = ({ isFavorite, isPromo, isActive, isViewed }: IGetIcon) => {
  if (isFavorite) {
    return renderToStaticMarkup(createElement(IconToggleHeartOn12, { color: 'icon-negative-default' }));
  }

  if (isPromo) {
    let color: TColors = isViewed ? 'icon-primary-disabled' : 'icon-positive-default';
    if (isActive) {
      color = 'icon-inverted-default';
    }

    return renderToStaticMarkup(createElement(IconFinancePercent12, { color }));
  }

  return '';
};
