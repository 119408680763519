import * as React from 'react';

/* istanbul ignore next */
export const ZoomOutNew: React.FC = () => {
  return (
    <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0V2H12V0H0Z" fill="var(--decorative-theme-dark)" />
    </svg>
  );
};

ZoomOutNew.displayName = 'ZoomOutNew';
