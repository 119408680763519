import { prepareNewbuilding } from './prepareNewbuilding';
import { IClusterSchema } from '../../../common/repositories/search-offers-map/entities/cluster/ClusterSchema';
import { TFeature, IFeatureProperties } from '../../types/map';

type TAdditionalFeaturesParams = Partial<{ [key in keyof IFeatureProperties]: IFeatureProperties[key] }>;

export function prepareFeatures(clusters: IClusterSchema[], additionalParams?: TAdditionalFeaturesParams): TFeature[] {
  return clusters.map(
    ({
      bbox,
      clusterOfferIds,
      coordinates,
      count,
      favoriteIds,
      geohash,
      hasNewobject,
      isViewed,
      minPrice = null,
      newbuildingPin,
      specialPromo,
      subdomain,
      isAnyFromKp,
      isAnyFromDeveloper,
      hasDiscountOffers,
    }) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [coordinates.lat, coordinates.lng],
        },
        id: geohash,
        properties: {
          bbox,
          clusterOfferIds,
          count,
          favoriteIds,
          hasNewobject,
          isViewed: isViewed || false,
          minPrice,
          specialPromo,
          newbuilding: prepareNewbuilding(newbuildingPin),
          subdomain,
          isAnyFromKp: !!isAnyFromKp,
          isAnyFromDeveloper: !!isAnyFromDeveloper,
          hasDiscountOffers: !!hasDiscountOffers,
          ...additionalParams,
        },
      };
    },
  );
}
