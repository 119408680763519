import { NonEmptyArray } from 'shared/common/packages/JsonQuery';
import { ESaleType } from 'shared/common/packages/api-models/common/json_query';

interface IPrepareValueProps {
  value: NonEmptyArray<ESaleType> | null;
  allowedValues: NonEmptyArray<ESaleType>;
}

export const prepareValue = ({ value, allowedValues }: IPrepareValueProps): NonEmptyArray<ESaleType> | null => {
  if (!value) {
    return null;
  }

  const filtered = value.filter(item => allowedValues.includes(item));

  return filtered.length ? (filtered as NonEmptyArray<ESaleType>) : null;
};
