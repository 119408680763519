import { FDealType, FOfferType, isAvailableStrict } from 'shared/common/packages/JsonQuery';

import { SubsidisedMortgageContainer } from '../../containers/advancedFilters/SubsidisedMortgageContainer';
import { IFilter } from '../../types/advancedFilters';
import { SUBSIDIZED_MORTGAGE_ENABLED_KEY } from '../config';

export const subsidisedMortgage: IFilter = {
  component: SubsidisedMortgageContainer,
  availability: (jsonQuery, _, config) => {
    return (
      Boolean(config.get<boolean>(SUBSIDIZED_MORTGAGE_ENABLED_KEY)) &&
      isAvailableStrict(FDealType.Sale, FOfferType.FlatOld)(jsonQuery)
    );
  },
};
