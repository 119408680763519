import { TColors } from '@cian/ui-kit-design-tokens/colors';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './CloseButton.css';

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface IButtonProps extends Omit<HTMLButtonProps, 'type' | 'className' | 'style'> {
  color?: TColors;
}

export const CloseButton: React.FC<IButtonProps> = ({ color, ...props }) => {
  return (
    <button {...props} className={styles['button']} type="button">
      <IconActionClose16 color={color} />
    </button>
  );
};

CloseButton.displayName = 'CloseButton';
