import { Button } from '@cian/ui-kit/button';
import { IconHeartOn16 } from '@cian/ui-kit/icons';

interface ISaveSearchButtonProps {
  onClick: () => void;
}

export function SaveSearchButton({ onClick }: ISaveSearchButtonProps) {
  return (
    <Button
      theme="fill_white_primary"
      size="XS"
      beforeIcon={<IconHeartOn16 color="icon-main-default" />}
      onClick={onClick}
    >
      Сохранить поиск
    </Button>
  );
}
