export const CANDY_PRIMARY_COLOR = 'var(--accent-negative-primary)';
export const CANDY_SECONDARY_COLOR = '#ffffff';
export const SHADOW_COLOR = 'var(--overlay-control-dark)';
export const SHADOW_BLUR = 5;
export const SHADOW_OFFSET = 2;
export const CANDY_BUTTON_RADIUS = 5;
export const CANDY_BUTTON_BORDER = 2;
export const LOLLIPOP_RADIUS = 10;
export const LOLLIPOP_INNER_RADIUS = 4;
export const LOLLIPOP_STICK = 8;
export const LOLLIPOP_STICK_COLOR = 'var(--overlay-media-banner)';
export const LOLLIPOP_BORDER = 1;
