import { FOfferType } from 'shared/common/packages/JsonQuery';
import { ESaleType } from 'shared/common/packages/api-models/common/json_query';

import { ISaleTypeType } from './types';

const FLAT_NEW_LIST: ISaleTypeType[] = [
  { label: 'Неважно', value: null },
  { label: '214-Ф3', value: ESaleType.F3 },
  { label: 'Договор ЖСК', value: ESaleType.GSKContract },
  { label: 'Переуступка', value: ESaleType.Reassignment },
  { label: 'Предварительный договор', value: ESaleType.PreliminaryContract },
  { label: 'Договор инвестирования', value: ESaleType.InvestmentContract },
];

const OTHERS_LIST: ISaleTypeType[] = [
  { label: 'Неважно', value: null },
  { label: 'Свободная', value: ESaleType.Free },
  { label: 'Альтернативная', value: ESaleType.Alternative },
];

export function getSaleTypesList(offerType: FOfferType): ISaleTypeType[] {
  if (offerType === FOfferType.FlatNew) {
    return FLAT_NEW_LIST;
  }

  return OTHERS_LIST;
}
