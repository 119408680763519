import { IconChevronDownSmall16 } from '@cian/ui-kit/icons';
import classNames from 'clsx';
import * as React from 'react';

import { getInlineStyles } from './helpers';
import * as styles from './selectButton.css';
import { EVisualSize, ISelectStyles, TSelectButtonAppearance } from '../types';

const APPEARANCE_TO_CLASS_MAP: { [key in TSelectButtonAppearance]: string } = {
  default: styles['button'],
  underlined: styles['button-underlined'],
};

interface ISelectButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  active: boolean;
  invalid?: boolean;
  size?: EVisualSize;
  style?: ISelectStyles;
  appearance?: TSelectButtonAppearance;
  withoutArrow?: boolean;
}

export const SelectButton = ({
  active,
  children,
  invalid,
  size,
  style,
  appearance = 'default',
  withoutArrow,
  ...htmlButtonProps
}: ISelectButtonProps) => (
  <button
    {...htmlButtonProps}
    type="button"
    className={classNames(
      APPEARANCE_TO_CLASS_MAP[appearance],
      active && styles['active'],
      invalid && styles['invalid'],
      size === EVisualSize.DESKTOP && styles['desktop'],
      size === EVisualSize.MOBILE && styles['mobile'],
    )}
    style={getInlineStyles(style)}
  >
    <span className={styles['content']}>{children}</span>

    {!withoutArrow && (
      <div className={styles['icon']}>
        <IconChevronDownSmall16 color={htmlButtonProps.disabled ? 'gray20_100' : 'icon-main-default'} />
      </div>
    )}
  </button>
);
