import { UIHeading5, UIText3 } from '@cian/ui-kit';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import * as styles from './styles.css';
import { BANNER_ID } from '../../../constants/banners';
import { IFskBanner } from '../../../types/fskContent/fskContent';
import { IApplicationState } from '../../../types/redux';
import { Ticker } from '../../Ticker';
import { MobileBannerTemplate, IMobileBannerTemplateProps } from '../MobileBannerTemplate';

type TMobileBannerProps = Omit<
  IMobileBannerTemplateProps,
  'rootClassName' | 'title' | 'description' | 'href' | 'copyright' | 'logo'
>;

export function MobileBanner(props: TMobileBannerProps) {
  const [bannerContainer, setContainer] = React.useState<HTMLElement | null>(null);
  const fskBanner = useSelector<IApplicationState, IFskBanner>(state => state.fskBanner);

  /**
   * Находит div контейнер созданный на ноде и маунтит в него точку входа в заявки
   * Нужно чтобы точка входа в заявки отображалась над шапкой
   */
  React.useEffect(() => {
    const container = document.getElementById(BANNER_ID);

    setContainer(container);
  }, []);

  if (!bannerContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <MobileBannerTemplate
      href={fskBanner.hitUrl}
      title={<UIHeading5 color="text-inverted-default">{fskBanner.title}</UIHeading5>}
      description={<UIText3 color="text-inverted-default">{fskBanner.description}</UIText3>}
      background={fskBanner.background}
      copyright={<Ticker text={fskBanner.disclaimer} />}
      logo={fskBanner.logo}
      rootClassName={styles['mobile-banner']}
      {...props}
    />,
    bannerContainer,
  );
}
