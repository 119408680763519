import { ModalPhone, EPageTypes, EPlatformTypes } from '@cian/frontend-newbuilding-call-component';
import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { onShowPhoneClickTrack } from './tracking/onShowPhoneClick';
import { closePhoneAction } from '../../actions/callPopup';
import { selectCallPopup, selectIsNewbuildingPromo } from '../../selectors/callPopup';
import { selectUser } from '../../selectors/user';
import { useApplicationContext } from '../ApplicationContext';

export const CallPopupContainer = () => {
  const callInfo = useSelector(selectCallPopup);
  const dispatch = useDispatch();
  const applicationContext = useApplicationContext();
  const isPromo = useSelector(selectIsNewbuildingPromo);
  const deviceType = useDeviceType();
  const user = useSelector(selectUser);

  const closePopup = React.useCallback(() => {
    dispatch(closePhoneAction());
  }, [dispatch]);

  const onShowPhoneClick = () => {
    /* istanbul ignore else */
    if (callInfo) {
      onShowPhoneClickTrack({
        label: callInfo.gaLabel,
        newbuildingId: callInfo.newbuildingId,
        isPromo,
        deviceType,
        user,
      });
    }
  };

  if (!callInfo) {
    return null;
  }

  return (
    <ModalPhone
      sellers={callInfo.sellers}
      builders={callInfo.builders}
      open={callInfo.open}
      onClose={closePopup}
      httpApi={applicationContext.httpApi}
      logger={applicationContext.logger}
      pageType={EPageTypes.Map}
      placeType="map_block"
      platformType={EPlatformTypes.WebDesktop}
      onShowPhoneClick={onShowPhoneClick}
    />
  );
};
