import { useMemo } from 'react';

import * as styles from './InfrastructureLegend.css';

interface IInfrastructureLegendProps {
  text: string;
  color?: string | null;
}

// istanbul ignore next
export function InfrastructureLegend({ color, text }: IInfrastructureLegendProps) {
  const style = useMemo(
    () => ({
      background: `${color || 'decorative-theme-dark'}`,
    }),
    [color],
  );

  return (
    <>
      <div className={styles['legend']} style={style} />
      <div className={styles['text']}>{text}</div>
    </>
  );
}
